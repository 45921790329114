var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("td", [
    _c(
      "div",
      { staticClass: "icon-wrap text-center mx-auto" },
      [
        _vm.value > 0
          ? _c("CIcon", {
              staticClass: "top",
              attrs: {
                name: "arrow-trends",
                width: "0.75rem",
                height: "0.5rem",
              },
            })
          : _vm.value < 0
          ? _c("CIcon", {
              attrs: {
                name: "arrow-trends-o",
                width: "1.4rem",
                height: "1.4rem",
              },
            })
          : _c("span", { staticClass: "equal" }, [_vm._v(" = ")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }