<template>
  <td>
    <div
      class="
        icon-wrap
        rounded-circle
        justify-content-center
        align-items-center
      "
      :class="{
        'bg-success': success,
        'bg-danger': danger,
        'd-none': equal,
        'd-flex': !equal
      }"
    >
      <CIcon
        name="trends-star"
        class="svg"
        width="0.5rem"
        height="0.5rem"
      />
    </div>
  </td>
</template>

<script>
export default {
  name: "TableCellPoint",
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    success () {
      return this.value > 0
    },
    danger () {
      return this.value < 0
    },
    equal () {
      return this.value === 0
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-wrap {
  width: 1.2rem;
  height: 1.2rem;
  .svg {
    color: #ffffff;
  }
}
</style>
