var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "move-to-top",
          rawName: "v-move-to-top",
          value: _vm.vMoveToTopOptions,
          expression: "vMoveToTopOptions",
        },
      ],
      staticClass: "table-wrap watch-selector-parent-scroll",
    },
    [
      _c("table", { staticClass: "table mb-0" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _c(
                "th",
                [
                  _c("table-cell-header", {
                    staticClass: "pl-2",
                    attrs: { "key-col": "coin.label", label: "Coin" },
                  }),
                ],
                1
              ),
              _vm._l(_vm.timeList, function (timeItem, i) {
                return _c(
                  "th",
                  { key: i },
                  [
                    _c("table-cell-header", {
                      attrs: {
                        "key-col": timeItem,
                        label: timeItem.toUpperCase(),
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "th",
                [
                  _c("table-cell-header", {
                    staticClass: "text-nowrap",
                    attrs: { "key-col": "all_tf", label: "ALL TF" },
                  }),
                ],
                1
              ),
              _c(
                "th",
                [
                  _c("table-cell-header", {
                    attrs: { "key-col": "bounce", label: "BOUNCE" },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.items, function (row, i) {
            return _c(
              "tr",
              {
                key: i,
                ref: "row",
                refInFor: true,
                class: row.class,
                attrs: { "data-is-mobile": _vm.isMobile },
                on: {
                  animationstart: function ($event) {
                    return _vm.animationStart(i)
                  },
                  animationend: function ($event) {
                    return _vm.animationEnd(row)
                  },
                },
              },
              [
                _c(
                  "td",
                  { staticClass: "position-relative" },
                  [
                    _c("watch-selector", {
                      attrs: {
                        ticker: row.coin.label,
                        "is-futures": _vm.isFutures,
                      },
                    }),
                    _c("table-cell-coin", {
                      staticClass: "pl-2",
                      attrs: { coin: row.coin },
                      on: {
                        clickTitle: function ($event) {
                          return _vm.$emit("clickTitle", row.coin.label)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(_vm.timeList, function (tf, i) {
                  return _c("table-cell", { key: i, attrs: { value: row[tf] } })
                }),
                _c("table-cell-point", { attrs: { value: row.all_tf } }),
                _c("table-cell-point", { attrs: { value: row.bounce } }),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }