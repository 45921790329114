<template>
  <td>
    <div class="icon-wrap text-center mx-auto">
      <CIcon
        v-if="value > 0"
        name="arrow-trends"
        class="top"
        width="0.75rem"
        height="0.5rem"
      />
      <CIcon
        v-else-if="value < 0"
        name="arrow-trends-o"
        width="1.4rem"
        height="1.4rem"
      />
      <span v-else class="equal">
        =
        <!--{{value}}-->
      </span>
    </div>
  </td>
</template>

<script>
export default {
  name: "TableCell",
  props: {
    value: {
      type: [Number, String]
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-wrap {
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    width: 1.4rem;
    height: 1.4rem;
  }
}
.top {
  color: var(--success);
}
.equal {
  line-height: 1;
  font-size: 1.4rem;
  color: var(--warning);
}
</style>
