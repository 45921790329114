<template>
  <div class="w-100">
    <div class="view-wrap">
      <div
        :class="{
          'row': isMobile,
          'c-row': isMobile,
          'h-100': !isMobile
        }"
        class="no-gutters"
      >
        <component
          :is="wrapComponent"
          name="fade"
          mode="out-in"
          class="row c-row no-gutters"
        >
          <CCol
            v-if="(isMobile && activeTab === 1) || !isMobile"
            key="1"
            col="12"
            md="7"
            xl="8"
            class="h-100"
          >
            <table-base
              :time-list="settings.trendsPeriods"
              :items="adapter.data"
              :is-futures="isActiveFutures"
              @clickTitle="openCandles"
            />
          </CCol>
          <CCol
            v-if="(isMobile && activeTab === 2) || !isMobile"
            key="2"
            col="12"
            md="5"
            xl="4"
            class="h-100 group-only-wrap"
          >
            <group-only
              :onlyPositive="adapter.allTfPositiveItems"
              :onlyNegative="adapter.allTfNegativeItems"
              :bounce="adapter.bounceItems"
              :is-futures="isActiveFutures"
              @selectItem="activeTab = 1"
            />
          </CCol>
        </component>
      </div>
      <nav-btns-mobile :tabs="tabs" :active-tab.sync="activeTab" />
    </div>

    <CandlesGraphModal
      ref="candles"
      :is-minutes="true"
      :settings="settings"
      :rows="adapter.data"
      :hide-notification-modal="isActiveFutures"
      :is-futures="isActiveFutures"
      @close="$emit('closeGraph')"
    />
  </div>
</template>

<script>
import TableBase from "@/components/screener/trends/TableBase"
import GroupOnly from "@/components/screener/trends/GroupOnly"
import screenerTrendAdapter from "@/assets/js/screenerTrendAdapter"
import axios from "axios"
import toastr from "toastr"
import CandlesGraphModal from "@/components/screener/tables/CandlesGraphModal"
import { mapGetters, mapActions } from "vuex";
import NavBtnsMobile from "@/components/common/NavBtnsMobile";
import {createWatcherExhangeAndScreen} from "@/mixins/watch-exhange-and-screen";
import {SCREENER_PAGE_FUTURES} from "@/shared/constants";

export default {
  name: "TrendsView",
  components: {
    TableBase,
    GroupOnly,
    CandlesGraphModal,
    NavBtnsMobile
  },
  props: {
    settings: Object,
    isPaused: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    createWatcherExhangeAndScreen(({ getData }) => getData()),
  ],
  data() {
    return {
      adapter: screenerTrendAdapter,
      loadingData: false,
      timer: null,
      timerIntervalDefault: 10000, // 60 sec
      CancelToken: axios.CancelToken,
      cancelEvent: null,
      activeTab: 1,
      tabs: [
        this.$t('screener.trendsView.trendSummary'),
        this.$t('screener.trendsView.inDetail')
      ]
    }
  },
  computed: {
    user(){
      return this.$store.state.user.userData
    },
    ...mapGetters({
      isBrowserTabActive: 'isBrowserTabActive',
      isMobile: 'mediaQuery/isMobile',
      isGuest: 'user/isGuest'
    }),
    wrapComponent() {
      return this.isMobile ? 'transition' : 'div'
    },
    isActiveFutures() {
      return this.currentScreenAndExchange.screen === SCREENER_PAGE_FUTURES
    },
  },
  created() {
    this.getData()
    // this.adapter.data = 'random'
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    if (this.cancelEvent) {
      this.cancelEvent()
    }
  },
  watch: {
    loadingData(val) {
      this.$emit('changeLoading', val)
    },
    isPaused(val) {
      if (val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData) {
          this.getData()
        }
      }
    },
    isBrowserTabActive(val) {
      if (!val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData && !this.isPaused) {
          this.getData()
        }
      }
    },
  },
  methods: {
    getData() {
      if (this.cancelEvent) {
        this.cancelEvent()
      }

      const getURL = this.isActiveFutures
        ? `/api/v3/${this.currentScreenAndExchange.exchange}_perp/screener/trends`
        : '/api/v2/screener/trends';

      this.loadingData = true
      axios.get(getURL, {
        cancelToken: new this.CancelToken((c) => {
          this.cancelEvent = c
        })
      })
        .then(res => {
          const arr = res?.data?.data
          if (arr && Array.isArray(arr)) {
            this.adapter.data = arr
          }

          this.loadingData = false

          const delay = res?.data?.delay
          this.startTimer(delay)
        })
        .catch((e) => {
          if (e instanceof axios.Cancel) {
            toastr.remove()
          } else if (e?.response?.status !== 401) {
            this.loadingData = false
            this.startTimer()
          }
        })
      // this.adapter.data = 'random'
    },
    startTimer(delay) {
      if (this.isPaused) return

      let localDelay = this.timerIntervalDefault
      if(this.isGuest){
        // каждую шестую минуту
        localDelay = 360000 - Date.now() % 300000
        if(localDelay < this.timerIntervalDefault){
          localDelay = this.timerIntervalDefault
        }
      } else if (delay && typeof delay === 'number') {
        localDelay = delay
      }

      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        if (this.loadingData) return
        this.getData()
      }, localDelay)
    },
    ...mapActions('promo', ['toggleShowPromoModal']),
    openCandles (title) {
      // const access = this.$store.getters['user/getPermissions']('view_screener_graphs')
      // if (!access) return
      if(this.user.getAccess(1)){
        this.$emit('openGraph')
        this.$refs.candles.openCandlesGraph(title, null)
      }else{
        this.toggleShowPromoModal({show: true, content: { id: 'pro', sliderData: [{videoName: "trends", isVideo: true}]}})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.view-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.c-row {
  height: calc(100% - 2.25rem);

  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.nav-wrap {
  height: 2.25rem;
  background: var(--bg);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .c-button {
    width: 50%;
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:not(.active) {
      color: var(--white);
      opacity: .6;
      transition-property: all;
    }

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.group-only-wrap {
  @include media-breakpoint-up(md) {
    padding-left: .6rem;
  }
}
</style>
