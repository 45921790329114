<template>
  <li
    class="list-item"
    @click="clickEvent"
  >
    <span class="list-item__value">{{data.coin.label}}</span>
  </li>
</template>
<script>
export default {
  name: 'Item',
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    clickEvent () {
      this.data.class = 'animation'
      this.$emit('selectItem', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  //&__value {
  //  color: #acaeb0;
  //}

  @include media-breakpoint-up(lg) {
    font-size: 0.8125rem;
  }
}
</style>
