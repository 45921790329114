var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.coin.label
    ? _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.coin.icon
          ? _c("div", { staticClass: "mr-2" }, [
              _c("img", {
                staticStyle: { width: "1.37rem", "flex-shrink": "0" },
                attrs: { src: _vm.coin.icon },
              }),
            ])
          : _vm._e(),
        _c("div", [
          _vm.coin.label
            ? _c(
                "div",
                {
                  staticClass: "m-0 overflow-hidden d-flex align-items-center",
                },
                [
                  _vm._v(" " + _vm._s(_vm.coin.label) + " "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: _vm.$t("screener.openGraph"),
                            container: ".c-app",
                          },
                          expression:
                            "{\n        content: $t('screener.openGraph'),\n        container: '.c-app',\n      }",
                        },
                      ],
                      staticClass: "cursor-pointer ml-1",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("clickTitle")
                        },
                      },
                    },
                    [
                      _c("CIcon", {
                        attrs: { width: "0.75rem", name: "cil-bar-chart" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.coin.current
            ? _c(
                "p",
                {
                  staticClass: "m-0 font-weight-bold",
                  staticStyle: { "font-size": "0.75rem" },
                },
                [_vm._v(" " + _vm._s(_vm.coin.current) + "$ ")]
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }