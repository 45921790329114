var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "list-item", on: { click: _vm.clickEvent } }, [
    _c("span", { staticClass: "list-item__value" }, [
      _vm._v(_vm._s(_vm.data.coin.label)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }