var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("td", [
    _c(
      "div",
      {
        staticClass:
          "icon-wrap rounded-circle justify-content-center align-items-center",
        class: {
          "bg-success": _vm.success,
          "bg-danger": _vm.danger,
          "d-none": _vm.equal,
          "d-flex": !_vm.equal,
        },
      },
      [
        _c("CIcon", {
          staticClass: "svg",
          attrs: { name: "trends-star", width: "0.5rem", height: "0.5rem" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }