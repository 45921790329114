var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "div",
        { staticClass: "view-wrap" },
        [
          _c(
            "div",
            {
              staticClass: "no-gutters",
              class: {
                row: _vm.isMobile,
                "c-row": _vm.isMobile,
                "h-100": !_vm.isMobile,
              },
            },
            [
              _c(
                _vm.wrapComponent,
                {
                  tag: "component",
                  staticClass: "row c-row no-gutters",
                  attrs: { name: "fade", mode: "out-in" },
                },
                [
                  (_vm.isMobile && _vm.activeTab === 1) || !_vm.isMobile
                    ? _c(
                        "CCol",
                        {
                          key: "1",
                          staticClass: "h-100",
                          attrs: { col: "12", md: "7", xl: "8" },
                        },
                        [
                          _c("table-base", {
                            attrs: {
                              "time-list": _vm.settings.trendsPeriods,
                              items: _vm.adapter.data,
                              "is-futures": _vm.isActiveFutures,
                            },
                            on: { clickTitle: _vm.openCandles },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  (_vm.isMobile && _vm.activeTab === 2) || !_vm.isMobile
                    ? _c(
                        "CCol",
                        {
                          key: "2",
                          staticClass: "h-100 group-only-wrap",
                          attrs: { col: "12", md: "5", xl: "4" },
                        },
                        [
                          _c("group-only", {
                            attrs: {
                              onlyPositive: _vm.adapter.allTfPositiveItems,
                              onlyNegative: _vm.adapter.allTfNegativeItems,
                              bounce: _vm.adapter.bounceItems,
                              "is-futures": _vm.isActiveFutures,
                            },
                            on: {
                              selectItem: function ($event) {
                                _vm.activeTab = 1
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("nav-btns-mobile", {
            attrs: { tabs: _vm.tabs, "active-tab": _vm.activeTab },
            on: {
              "update:activeTab": function ($event) {
                _vm.activeTab = $event
              },
              "update:active-tab": function ($event) {
                _vm.activeTab = $event
              },
            },
          }),
        ],
        1
      ),
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: {
          "is-minutes": true,
          settings: _vm.settings,
          rows: _vm.adapter.data,
          "hide-notification-modal": _vm.isActiveFutures,
          "is-futures": _vm.isActiveFutures,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("closeGraph")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }