<template>
  <div class="table-wrap watch-selector-parent-scroll" v-move-to-top="vMoveToTopOptions">
    <table class="table mb-0">
      <thead>
        <tr>
          <th>
            <table-cell-header
              class="pl-2"
              key-col="coin.label"
              label="Coin"
            />
          </th>
          <th v-for="(timeItem, i) in timeList" :key="i">
            <table-cell-header
              :key-col="timeItem"
              :label="timeItem.toUpperCase()"
            />
          </th>
          <th>
            <table-cell-header
              class="text-nowrap"
              key-col="all_tf"
              label="ALL TF"
            />
          </th>
          <th>
            <table-cell-header
              key-col="bounce"
              label="BOUNCE"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, i) in items"
          :key="i"
          :ref="'row'"
          :class="row.class"
          :data-is-mobile="isMobile"
          @animationstart="animationStart(i)"
          @animationend="animationEnd(row)"
        >
          <td class="position-relative">
            <watch-selector
              :ticker="row.coin.label"
              :is-futures="isFutures"
            />
            <table-cell-coin
              class="pl-2"
              :coin="row.coin"
              @clickTitle="$emit('clickTitle', row.coin.label)"
            />
          </td>
          <table-cell
            v-for="(tf, i) in timeList"
            :key="i"
            :value="row[tf]"
          />
          <table-cell-point :value="row.all_tf" />
          <table-cell-point :value="row.bounce" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableCell from "@/components/table/TableCellNumber"
import TableCellPoint from "@/components/table/TableCellPoint"
import TableCellCoin from "@/components/table/TableCellCoin"
import TableCellHeader from "@/components/screener/trends/table/TableCelHeader"
import WatchSelector from "@/components/screener/WatchSelector"
import {mapGetters} from "vuex";
export default {
  name: "TableBase",
  components: {
    TableCell,
    TableCellPoint,
    TableCellCoin,
    TableCellHeader,
    WatchSelector
  },
  props: {
    items: Array,
    timeList: Array,
    isFutures: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isDesktop: 'mediaQuery/isDesktop',
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
    }),
    vMoveToTopOptions() {
      if (this.isMobile) {
        return {
          position: {bottom: 80, right: 20}
        }
      } else return null
    }
  },
  methods: {
    animationStart (i) {
      const item = this.$refs['row'][i]
      if (item) {
        item.scrollIntoView({behavior: this.isMobile ? 'auto' : 'smooth', block: 'center'})
      }
    },
    animationEnd (item) {
      item.class = ''
    }
  }
}
</script>

<style lang="scss" scoped>

.table-wrap {
  overflow: auto;
  width: 100%;
  height: 100%;
  border-radius: .25rem;
}

.table {
  position: relative;
  tr {
    background: var(--second-card-bg);
  }
  thead tr th {
    background: var(--dark);
    border: none;
    position: sticky;
    top: -1px;
    z-index: 1;
    padding: .75rem .4375rem;

    &:first-child {
      padding-left: .875rem;
    }

    &:last-child {
      padding-right: .875rem;
    }

    @include media-breakpoint-up(lg) {
      padding: .75rem !important;
    }
  }

  tbody tr td {
    vertical-align: middle;
    padding: .25rem .4375rem;
    font-size: .6875rem;

    @include media-breakpoint-up(md) {
      font-size: .75rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: .875rem;
      padding: .75rem !important;
    }

    &:first-child {
      padding-left: .875rem;
    }

    &:last-child {
      padding-right: .875rem;
    }
  }
}

.label-custom {
  font-size: 0.6rem;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.animation {
  animation-name: radial-pulse-fake;

  &,
  td {
    animation-duration: 3s;
    animation-timing-function: ease-in;
  }

  td {
    animation-name: radial-pulse;
  }

  td:first-child {
    animation-name: radial-pulse-first;
  }

  td:last-child {
    animation-name: radial-pulse-last;
  }

  &[data-is-mobile] {
    &,
    td {
      animation-iteration-count: 3;
    }
  }
}

@keyframes radial-pulse-fake {
  0%, 32%, 64%, 100% {
    box-shadow: inset 0 0 0 0 var(--primary);
  }
  16%, 48%, 81% {
    box-shadow: inset 0 0 0 0 var(--primary);
  }
}

@keyframes radial-pulse {
  0%, 32%, 64%, 100% {
    box-shadow:
      inset 0 2rem 0 -2rem var(--primary),
      inset 0 -2rem 0 -2rem var(--primary);
  }
  16%, 48%, 81% {
    box-shadow:
      inset 0 2rem 1.5rem -2rem var(--primary),
      inset 0 -2rem 1.5rem -2rem var(--primary);
  }
}

@keyframes radial-pulse-first {
  0%, 32%, 64%, 100% {
    box-shadow:
      inset 0 2rem 0 -2rem  var(--primary),
      inset 0 -2rem 0 -2rem  var(--primary),
      inset 2rem 0 0 -2rem  var(--primary);
  }
  16%, 48%, 81% {
    box-shadow:
      inset 0 2rem 1.5rem -2rem  var(--primary),
      inset 0 -2rem 1.5rem -2rem  var(--primary),
      inset 2rem 0 1.5rem -2rem  var(--primary);
  }
}

@keyframes radial-pulse-last {
  0%, 32%, 64%, 100% {
    box-shadow:
      inset 0 2rem 0 -2rem  var(--primary),
      inset 0 -2rem 0 -2rem  var(--primary),
      inset -2rem 0 0 -2rem  var(--primary);
  }
  16%, 48%, 81% {
    box-shadow:
      inset 0 2rem 1.5rem -2rem  var(--primary),
      inset 0 -2rem 1.5rem -2rem  var(--primary),
      inset -2rem 0 1.5rem -2rem  var(--primary);
  }
}
</style>
