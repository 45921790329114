<template>
  <div class="d-flex align-items-center" v-if="coin.label">
    <div v-if="coin.icon" class="mr-2">
      <img :src="coin.icon" style="width: 1.37rem; flex-shrink: 0">
    </div>
    <div>
      <div
        v-if="coin.label"
        class="m-0 overflow-hidden d-flex align-items-center"
      >
        {{coin.label}}
        <div
        class="cursor-pointer ml-1"
        @click="$emit('clickTitle')"
        v-tooltip="{
          content: $t('screener.openGraph'),
          container: '.c-app',
        }">
          <CIcon
          width="0.75rem"
          name="cil-bar-chart"
        />
        </div>
    </div>
      <p
        v-if="coin.current"
        class="m-0 font-weight-bold"
        style="font-size: 0.75rem"
      >
        {{coin.current}}$
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableCellCoin",
  props: {
    coin: Object
  }
}
</script>

<style scoped>

</style>
