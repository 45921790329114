<template>
  <CCard class="shadow-none h-100 group-only" color="second-card-bg">
    <CCardBody class="d-flex flex-column p-0">
      <CRow class="no-gutters c-row-head">
        <CCol
          class="col-4 text-nowrap"
          :class="{ 'pl-4': onlyPositive.length }"
        >
          <watch-selector
            v-if="onlyPositive.length"
            :showed-rows="onlyPositive"
            :is-futures="isFutures"
          />
          <span class="font-weight-bold mr-2">Only</span>
          <CIcon
            name="arrow-trends"
            class="top"
            width="0.75rem"
            height="0.5rem"
          />
          <span class="ml-2">{{onlyPositive.length}}</span>
        </CCol>

        <CCol
          class="col-4 text-nowrap"
          :class="{ 'pl-4': onlyNegative.length }"
        >
          <watch-selector
            v-if="onlyNegative.length"
            :showed-rows="onlyNegative"
            :is-futures="isFutures"
          />
          <span class="font-weight-bold mr-2">Only</span>
          <CIcon
            name="arrow-trends-o"
            width="1.4rem"
            height="1.4rem"
            class="bottom"
          />
          <span class="ml-2">{{onlyNegative.length}}</span>
        </CCol>

        <CCol
          class="col-4 text-nowrap"
          :class="{ 'pl-4': bounce.length }"
        >
          <watch-selector
            v-if="bounce.length"
            :showed-rows="bounce"
            :is-futures="isFutures"
          />
          <span class="font-weight-bold">Bounce</span>
          <span
            class="rounded-circle tooltip-wrap d-inline-flex justify-content-center ml-2"
            v-c-tooltip="{
              content: $t('screener.trendsView.bounceDesc'),
              appendToBody: true,
              popperOptions: {
                strategy: 'fixed'
              }
            }"
          >
            ?
          </span>
          <span class="ml-2">{{bounce.length}}</span>
        </CCol>
      </CRow>

      <CRow class="flex-grow-1 overflow-auto no-gutters c-row-body" v-move-to-top="vMoveToTopOptions">
        <CCol class="col-4">
          <ul v-if="onlyPositive.length" class="list">
            <Item
              class="list__item"
              v-for="(item, i) in onlyPositive"
              :key="i"
              :data="item"
              @selectItem="selectItem"
            />
          </ul>
        </CCol>

        <CCol class="col-4">
          <ul v-if="onlyNegative.length"  class="list">
            <Item
              class="list__item"
              v-for="(item, i) in onlyNegative"
              :key="i"
              :data="item"
              @selectItem="selectItem"
            />
          </ul>
        </CCol>

        <CCol class="col-4">
          <ul v-if="bounce.length"  class="list">
            <Item
              class="list__item"
              v-for="(item, i) in bounce"
              :key="i"
              :data="item"
              :class="item.bounceClasses"
              @selectItem="selectItem"
            />
          </ul>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Item from "@/components/screener/trends/groupOnly/Item";
import {mapGetters} from "vuex";
import WatchSelector from "@/components/screener/WatchSelector";

export default {
  name: "GroupOnly",
  components: {
    Item,
    WatchSelector,
  },
  props: {
    bounce: {
      type: Array,
      default: () => ([])
    },
    onlyNegative: {
      type: Array,
      default: () => ([])
    },
    onlyPositive: {
      type: Array,
      default: () => ([])
    },
    isFutures: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      prevItemAnimation: null
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'mediaQuery/isDesktop',
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
    }),
    vMoveToTopOptions() {
      if (this.isTablet || this.isMobile) {
        return {
          position: {bottom: 80, right: 20}
        }
      } else return {
          position: {bottom: 80, right: -35}
        }
    }
  },
  methods: {
    selectItem (item) {
      if (this.prevItemAnimation) {
        if (this.prevItemAnimation.id !== item.id) {
          this.prevItemAnimation.class = null
        }
      }

      this.prevItemAnimation = item
      this.$emit('selectItem', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  color: var(--success);
  flex-shrink: 0;
}
.bottom {
  color: var(--danger);
  width: 1.1rem;
  height: 1.1rem;
  flex-shrink: 0;

  @include media-breakpoint-up(lg) {
    width: 1.4rem;
    height: 1.4rem;
  }
}
.list {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
  &__item {
    margin-bottom: 1rem;
    color: #acaeb0;
  }
}

.tooltip-wrap {
  cursor: pointer;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
  flex-shrink: 0;
  background: var(--dark);

  @include media-breakpoint-down(md) {
    background: var(--bg);
  }
}

.text-nowrap {
  font-size: .75rem;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    font-size: .875rem;
  }
}

.c-row-head {
  padding: .75rem .875rem;
  background: var(--dark);

  @include media-breakpoint-up(md) {
    padding: .75rem .5rem;
  }

  @include media-breakpoint-up(lg) {
    background: transparent;
  }

  @include media-breakpoint-up(xl) {
    padding: .75rem .875rem;
  }
}

.c-row-body {
  padding: .75rem .875rem;
}

.group-only {
  border-radius: .25rem;
  overflow: hidden;
}
</style>
