<template>
  <div class="table-cell-header d-flex align-items-center user-select-none">
    <span class="mr-2 text">
      {{label}}
    </span>
    <CIcon
      class="switcher"
      :class="{
        _active: sorting,
        _reverse: sorting === 'asd'
      }"
      name="arrow-strong"
      @click.native="changeSort"
    />
  </div>
</template>

<script>
import screenerTrendAdapter from "@/assets/js/screenerTrendAdapter"
export default {
  name: "TableCellHeader",
  props: {
    keyCol: String,
    label: String
  },
  data () {
    return {
      adapter: screenerTrendAdapter
    }
  },
  computed: {
    sorting () {
      if (
        this.adapter.sortCol === this.keyCol &&
        ['asd', 'desc'].indexOf(this.adapter.sortType) !== -1
      ) {
        return this.adapter.sortType
      } else {
        return null
      }
    }
  },
  methods: {
    changeSort () {
      if (this.adapter.sortCol === this.keyCol) {
        if (!this.sorting) {
          this.adapter.sortType = 'desc'
        } else if (this.sorting === 'desc') {
          this.adapter.sortType = 'asd'
        } else {
          this.adapter.sortType = null
        }
      } else {
        this.adapter.sortCol = this.keyCol
        this.adapter.sortType = 'desc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.switcher {
  cursor: pointer;
  z-index: 1;
  opacity: 0.15;
  transition: all 250ms ease-out;
  &:hover {
    opacity: 0.3;
  }
  &._active {
    opacity: 1;
  }
  &._reverse {
    transform: rotate(180deg);
  }
}

.table-cell-header {
  font-size: .75rem;

  @include media-breakpoint-up(lg) {
    font-size: .875rem;
  }
}
</style>
