import { render, staticRenderFns } from "./TableCelHeader.vue?vue&type=template&id=060e645d&scoped=true&"
import script from "./TableCelHeader.vue?vue&type=script&lang=js&"
export * from "./TableCelHeader.vue?vue&type=script&lang=js&"
import style0 from "./TableCelHeader.vue?vue&type=style&index=0&id=060e645d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "060e645d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/user0/cvizor/cvizor-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('060e645d')) {
      api.createRecord('060e645d', component.options)
    } else {
      api.reload('060e645d', component.options)
    }
    module.hot.accept("./TableCelHeader.vue?vue&type=template&id=060e645d&scoped=true&", function () {
      api.rerender('060e645d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/screener/trends/table/TableCelHeader.vue"
export default component.exports